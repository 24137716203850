import v, { jsxs as S, jsx as r } from "react/jsx-runtime";
import { i as y } from "../../../styled-706f6038.js";
import { r as w } from "../../../createSvgIcon-3ff6de40.js";
import "../../../Chatbot.style-3facfdc9.js";
import { Box as z, OutlinedInput as f, InputAdornment as I, IconButton as A } from "@mui/material";
import { useState as C } from "react";
import "../FormControl/FormControl.js";
import "../FormControlLabel/FormControlLabel.js";
import "../../../map-85279df3.js";
import "../../../server.browser-ec1ee1ca.js";
import "../Grid/Grid.js";
import "../Paper/Paper.js";
import "../Box/Box.js";
import "../Radio/Radio.js";
import "../RadioGroup/RadioGroup.js";
import { Typography as h } from "../Typography/Typography.js";
import "../Stepper/Stepper.js";
import "../Step/Step.js";
import "../StepLabel/StepLabel.js";
import "../../../style.module-3bbdaa05.js";
import "../../../DateTimeSelectorMenu-84657d02.js";
import "moment-timezone";
import "../../../runtime-6393d96e.js";
import "../../../TableContext-c60e49ca.js";
import "../../ReactTable/SearchFilter.js";
import "../../ReactTable/TablePaginationActions.js";
import "../../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "../../molecules/Widgets/WidgetTimelineGraph/WidgetTimelineGraph.styles.js";
import "../../../transform-17824b7f.js";
import "../../molecules/NetworkMap/NetworkMapUtils.js";
import "../../../ChatbotContext-74017a6b.js";
var l = {}, P = y;
Object.defineProperty(l, "__esModule", {
  value: !0
});
var g = l.default = void 0, j = P(w()), D = v, $ = (0, j.default)(/* @__PURE__ */ (0, D.jsx)("path", {
  d: "M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
}), "Visibility");
g = l.default = $;
var n = {}, O = y;
Object.defineProperty(n, "__esModule", {
  value: !0
});
var b = n.default = void 0, R = O(w()), q = v, T = (0, R.default)(/* @__PURE__ */ (0, q.jsx)("path", {
  d: "M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"
}), "VisibilityOff");
b = n.default = T;
function gr(o) {
  const {
    variant: p = "outlined",
    width: s,
    type: d = "text",
    height: u,
    sx: t,
    label: m,
    error: i,
    errorText: c,
    ...e
  } = o, [a, _] = C(!1);
  return /* @__PURE__ */ S(z, { sx: { display: "flex", flexDirection: "column", gap: "4px", width: "100%" }, children: [
    m && /* @__PURE__ */ r(h, { sx: B, children: m }),
    d === "password" ? /* @__PURE__ */ r(
      f,
      {
        id: "outlined-adornment-password",
        type: a ? "text" : "password",
        error: i,
        endAdornment: /* @__PURE__ */ r(I, { position: "end", children: /* @__PURE__ */ r(
          A,
          {
            "aria-label": "toggle password visibility",
            onClick: () => {
              _(!a);
            },
            onMouseDown: (M) => {
              M.preventDefault();
            },
            edge: "end",
            children: a ? /* @__PURE__ */ r(b, {}) : /* @__PURE__ */ r(g, {})
          }
        ) }),
        sx: [
          { width: s, height: u },
          x[p],
          ...Array.isArray(t) ? t : [t]
        ],
        placeholder: e.placeholder ? e.placeholder : "",
        ...e
      }
    ) : /* @__PURE__ */ r(
      f,
      {
        sx: [
          {
            width: s,
            height: u
          },
          x[p],
          ...Array.isArray(t) ? t : [t]
        ],
        error: i,
        type: d,
        ...e
      }
    ),
    i && c && /* @__PURE__ */ r(h, { sx: V, children: c })
  ] });
}
const x = {
  outlined: (o) => ({
    borderColor: `${o.palette.grey[600]}`,
    borderRadius: "8px",
    textTransform: "none",
    gap: 3,
    fontSize: "14px",
    marginTop: "2px !important",
    ".MuiInputBase-root": {
      borderRadius: 2
    },
    "&>.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#14141499"
    }
  }),
  filled: () => ({}),
  standard: () => ({})
}, B = {
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "14px",
  color: "#5A5A5A"
}, V = {
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "14px",
  color: "#d32f2f"
};
export {
  gr as Input
};
